import create from 'zustand';
import produce from "immer";

const getLocalStorage = (key) => JSON.parse(window.localStorage.getItem(key));
const setLocalStorage = (key, value) => window.localStorage.setItem(key, JSON.stringify(value));
const deleteLocalStorage = (key) => window.localStorage.removeItem(key);

export const useDebugStore = create((set, get) => ({

    saveStates: getLocalStorage("saveStates") || [],
    addSaveState: (globalVars, name) => set(state => {

            let saveState = {
                name: name,
                globalVars: globalVars
            }
            
            const index = state.saveStates.findIndex(s => s.name === name);
            
            // already saved
            if (index !== -1) {
                console.log(`save state with name ${name} already exists, overriding it...`)

                state.saveStates[index] = saveState;
                return;
            }
            else {
                state.saveStates.push(saveState)
            }
            
            setLocalStorage("saveStates", state.saveStates);
            return;
    }),
    deleteSaveState: (name) => set(state => {
        const index = state.saveStates.findIndex(s => s.name === name);
        
        if (index !== -1) {
            state.saveStates.splice(index, 1);
            setLocalStorage("saveStates", state.saveStates);
        }
    }),
    getSaveState: (name) => {
        return get().saveStates.filter(s => s.name === name)[0];
    }
    
}))

export default useDebugStore;