import React, { useEffect, useState, useRef } from 'react';
import {
    determineNewActionArray,
    getSelectedSceneFromId,
    executeNewPlayerAction,
    getVariableWithName,
    getEntityWithSpeakerId
} from '../../jsonData/DataWrangler';

import { BiChevronsRight } from 'react-icons/bi';
import { shuffleArray } from "../../Misc/utilities";

const WhatsappOptions = ({ currentNode, delay, openCollectablesModal, onClickedOption }) => {

    const [buttonsActive, setButtonsState] = useState(true);
    const [collectablesModalState, setCollectablesModelState] = useState(false);

    const [collectablesName, setCollectablesName] = useState(getVariableWithName("GameSettings", "collectablesName")?.Value);
    const showCollectableButton = getVariableWithName("GameSettings", "meldKnop")?.Value.toLowerCase() || process.env.REACT_APP_SHOW_COLLECTABLES_MODAL;

    let btnRef = useRef();
    let availableActions = determineNewActionArray(currentNode);

    useEffect(() => {
        if (delay === 0)
            return;

        setButtonsState(false);
        setTimeout(() => {
            setButtonsState(true);
        }, delay + 500);
    }, [delay]);

    function onClickOption(option) {

        if (buttonsActive === false)
            return;

        onClickedOption(option);
    }

    const toggleCollectablesModal = () => {
        openCollectablesModal();

        setCollectablesModelState(!collectablesModalState);
    }


    const verticalLayoutOptions = (actionArray) => {
        actionArray.map((option, key) => {
            return (<button ref={btnRef} onClick={() => onClickOption(option)} key={key}
                            className={`rounded-2xl rounded-tr-none px-5 py-4 shadow-lg outline-none focus:outline-none transition duration-100
                            transform hover:scale-99 text-md text-persona-greenDark bg-opacity-80 hover:bg-opacity-100 tracking-wider bg-persona-white hover:bg-persona-white hover:text-persona-greenDark
                            w-full ${buttonsActive === false ? '' : ''}`}>
                {option.Properties.Text}
            </button>)
        })}

    const verticalLayout = () => {
        return <>
            {availableActions.map((option, key) => {

                return (
                    <button ref={btnRef}
                            onClick={() => onClickOption(option)}
                            key={key}
                            className={`rounded-2xl mb-2 rounded-tr-none px-5 py-4 shadow-lg outline-none focus:outline-none transition duration-100
                                transform hover:scale-99 text-md text-persona-greenDark bg-opacity-80 hover:bg-opacity-100 tracking-wider bg-persona-white hover:bg-persona-white hover:text-persona-greenDark
                                w-full ${buttonsActive === false ? '' : ''}`}>
                        {option.Properties.Text}
                    </button>
                )
            })}
        </>;
    }

    const gridLayout = () => {
        return <>
            <div className="max-h-30r overflow-y-auto relative">
                {/*<div className="absolute left-0 top-0 w-full z-10 h-24"/>*/}
                <div className="grid grid-flow-row grid-cols-5 gap-1.5">
                    {
                        availableActions.map((option, key) => {
                            return (
                                <button ref={btnRef}
                                        onClick={() => onClickOption(option)}
                                        key={key}
                                        className={`p-2 rounded-lg shadow-lg outline-none focus:outline-none transition duration-100
                                transform text-md text-persona-greenDark bg-opacity-80 hover:bg-opacity-100 tracking-wider bg-persona-white hover:bg-persona-white hover:text-persona-greenDark
                                w-full ${buttonsActive === false ? '' : ''}`}>
                                    <div className="my-auto">{option.Properties.Text}</div>
                                </button>
                            )
                        })
                    }
                </div>
            </div>
        </>
    }

    return (
        <div className="w-full mt-2">
            <div className="rounded-2xl w-full px-4 mb-5">
                <div className="flex flex-row space-x-2">
                    <div className="flex flex-row text-persona-white relative flex-1 items-center">
                        <BiChevronsRight className="h-12 w-16 text-persona-greenLight flex-none"/>
                        <div className="text-md flex-grow font-semibold opacity-70">Kies een reactie</div>

                        {showCollectableButton === 'true' && <button
                            className={`text-persona-white z-20 border-2 border-persona-white hover:bg-persona-greenLight ${collectablesModalState === true ? 'bg-persona-greenLight' : 'bg-none'} text-sm rounded-lg text-center p-1 px-2 my-1`}
                            onClick={() => toggleCollectablesModal()}>
                            {collectablesModalState === false ? `Open ${collectablesName} melder` : `Sluit ${collectablesName} melder`}
                        </button>}
                    </div>
                </div>
                {
                    availableActions.length <= 5 ?
                        verticalLayout() :
                        gridLayout()
                }
            </div>
        </div>
    );
}

export default WhatsappOptions;