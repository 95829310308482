import React, { useEffect, useState } from 'react';
import { MdOutlineDelete } from 'react-icons/md';
import { animated, useTransition, config } from 'react-spring';

const MissionUpdate = ({ pendingMissionUpdates, dismissMissionUpdate }) => {

    const [updatesShown, setUpdatesShown] = useState(false);

    useEffect(() => {
        if (pendingMissionUpdates.length <= 0) {
            setUpdatesShown(false);
        }
    }, [pendingMissionUpdates])

    return (

        <div className="">
            {
                pendingMissionUpdates.length > 0 ?
                    <div className={`p-0 fixed top-32 right-0 pr-5 ${updatesShown ? 'pl-5 w-full' : ''}`}>
                        <div className={`relative ${updatesShown ? ' p-4 bg-persona-greenLight bg-opacity-95 shadow-agressive' : 'mt-4 mr-4'} flex flex-col items-end rounded-xl`}>
                            <div className="top-0 right-0 flex items-center">
                                {updatesShown ? <p className="mr-6 font-header text-persona-white text-3xl tracking-wider">Missie updates</p> : null}
                                <div className="bg-gradient-to-br from-persona-greenLight to-persona-greenDark
                 w-20 h-20 flex flex-wrap content-center justify-center rounded-full shadow-xl
                 border-2 border-persona-white cursor-pointer" onClick={() => setUpdatesShown(!updatesShown)}>
                                    <p className="text-persona-white font-bold text-4xl tracking-wider">{pendingMissionUpdates.length}</p>
                                </div>
                            </div>
                            {
                                updatesShown ?
                                    <div className="flex-1 w-full text-center font-header tracking-wider space-y-5 mt-4">
                                        {pendingMissionUpdates.map((mu, key) => {
                                            return (
                                                <MissionUpdateBtn key={key} dismissMissionUpdate={dismissMissionUpdate} mu={mu} />
                                            )
                                        })}
                                    </div> :
                                    null
                            }
                        </div>
                    </div> : null
            }
        </div>
    )
}

export default React.memo(MissionUpdate)



export const MissionUpdateBtn = ({ dismissMissionUpdate, mu }) => {

    const transitions = useTransition(true, {
        from: {
            opacity: 1,
        },
        enter: {
            opacity: 1,
        },
        leave: {
            opacity: 0,
        },
        trail: 100,
    })

    return transitions(
        (styles, item) => item && <animated.div style={styles} className="flex bg-gradient-to-r from-persona-greenMiddle to-persona text-persona-white p-1 cursor-pointer rounded-lg items-center px-3 shadow-lg persona-btn-hover"
            onClick={() => dismissMissionUpdate(mu)}>
            <div className="flex-1 text-lg mr-5">
                <p className="">{mu.Properties.Text}</p>
            </div>
            <MdOutlineDelete className="flex-0 h-full w-8" />
        </animated.div>
    )
}



