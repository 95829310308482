import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-reader';

import 'react-dropdown/style.css';
import { useHistory } from "react-router-dom";
import metadata from '../metadata.json';

import { ReactComponent as Logo } from '../images/MadLogicLogo.svg';
import { RiArrowGoBackLine } from 'react-icons/ri';
import { FaChrome, FaSafari } from 'react-icons/fa';
import Dropdown from 'react-dropdown';
import {useAuth} from "../Auth/auth";
import useUserStore from "../Stores/userStore";

const dropdownOptions = metadata.startQRCodes;
const defaultOption = dropdownOptions[0];

const ScanScreen = ({ handleScan }) => {

    const auth = useAuth();

    const [scanError, setScanError] = useState('');
    const [scanResult, setScanResult] = useState('');
    const [user] = useUserStore(state => [state.user])

    const history = useHistory();


    const addImages = () => {
        const imgs = [];
        imgs.push({
            label: "1",
            img: 'images/1.png',
            offsetX: '0px',
            offsetY: '5px',
        });
        imgs.push({
            label: "2",
            img: 'images/2.png',
            offsetX: 0,
            offsetY: '-50px',
        });
        imgs.push({
            label: "3",
            img: 'images/3.png',
            offsetX: 0,
            offsetY: 0,
        });
        imgs.push({
            label: "4",
            img: 'images/4.png',
            offsetX: 0,
            offsetY: 5,
        });
        imgs.push({
            label: "5",
            img: 'images/5.png',
            offsetX: 0,
            offsetY: 0,
        });
        imgs.push({
            label: "6",
            img: 'images/6.png',
            offsetX: 0,
            offsetY: 0,
        });
        imgs.push({
            label: "7",
            img: 'images/7.png',
            offsetX: 0,
            offsetY: '-28px',
        });
        imgs.push({
            label: "8",
            img: 'images/8.png',
            offsetX: 0,
            offsetY: '-15px',
        });
        imgs.push({
            label: "9",
            img: 'images/9.png',
            offsetX: 1,
            offsetY: '-20px',
        });
        imgs.push({
            label: "10",
            img: 'images/10.png',
            offsetX: 0,
            offsetY: '-132px',
        });
        imgs.push({
            label: "11",
            img: 'images/11.png',
            offsetX: 0,
            offsetY: 0,
        });
        imgs.push({
            label: "12",
            img: 'images/12.png',
            offsetX: 0,
            offsetY: '-20px',
        });

        for (let i = 0; i < imgs.length; i++) {
            const value = metadata.startQRCodes.filter(e => e.label === imgs[i].label);
            if (value == null || value.length < 1) {
                console.error("No value found for id: [" + imgs[i].label + "], please fix in ScanScreen.jsx");
                break;
            }

            imgs[i].value = metadata.startQRCodes.filter(e => e.label === imgs[i].label)[0].value;
        }

        return imgs;
    }
    const [btnImages, setBtnImages] = useState(addImages())

    useEffect(() => {
        if (scanResult.length > 0) {
            handleScan(scanResult);
        }
    }, [scanResult])

    function handleScanBtn(data) {
        if (data) {
            setScanResult(data);
            setScanError('');
        }
    }

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    function handleScanError(err) {
        setScanError('Error ' + err);
        console.log("scan error: " + err);
    }

    function onSelectButton(val) {
        setScanResult(val.value);
    }

    return (
        <div className="h-screen mx-auto flex flex-col relative bg-persona-white bg-opacity-10">

            { !scanError &&
                <>
                    <QrReader className=""
                          delay={300}
                          showViewFinder={false}
                          onError={handleScanError}
                          onScan={handleScanBtn}
                    />
                    <div className="w-full h-100 absolute">
                        <svg className="text-persona-white fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.197 178.197"><path d="M0 0v49.895h14.256v-7.128H7.128V7.127h35.64v7.129h7.127V0zm71.279 0v14.256h7.128V0zm14.256 0v7.128h7.128V0zm28.511 0v7.128H99.79v7.128h21.384V0zm14.256 0v14.256h7.128V7.128h35.64v35.64h-7.129v7.127h14.256V0zM57.023 7.128v7.128h7.128V7.128zM0 57.023v7.128h7.128v7.128h7.128V57.023zm7.128 14.256H0v7.128h7.128zm0 7.128v42.767h7.128V78.407zm156.813-21.384v21.384h7.128v7.127h-7.128v21.384h7.128v7.128h7.128V99.79h-7.128v-7.127h7.128V71.279h-7.128V57.023zM0 128.302v49.895h49.895V163.94h-7.128v7.128H7.128v-35.64h7.128v-7.127zm163.941 0v14.256h14.256v-14.256zm0 21.383v7.128h7.128v14.256h-14.256v-7.128h-49.895v7.128h14.256v7.128h7.128v-7.128h7.128v7.128h42.767v-28.512zm-57.023 21.384H85.535v7.128h21.383zm-21.383 0v-7.128h-7.128v7.128zm-7.128 0h-7.128v-7.128H57.023v14.256h21.384z" /></svg>
                    </div>
                </>
            }

            { scanError &&
                <div className="mx-auto w-full flex flex-col space-y-5 text-center text-lg bg-persona-white bg-opacity-70 rounded-xl p-5 mt-24 mb-12">

                    <details>
                        <summary className="bg-persona-incorrect font-semibold text-persona-white py-3 px-4 rounded-full shadow-lg cursor-pointer bg-opacity-90 hover:bg-opacity-100">
                            Geen toegang tot de camera
                        </summary>
                        <p className="leading-relaxed mt-3">Als je dit ziet heeft je telefoon waarschijnlijk geen toegang om de camera te gebruiken!</p>
                        <p className="leading-relaxed mt-3">Klik hieronder om er achter te komen hoe je toch toegang kunt geven</p>
                        <div className="flex flex-col my-4 space-y-4 text-persona-white">
                            <div onClick={() => openInNewTab(metadata.permissionsChromePath)} className="flex flex-row items-center space-x-5 bg-persona p-2 rounded-lg shadow-lg bg-opacity-80 hover:bg-opacity-100 cursor-pointer">
                                <FaChrome className="h-14 w-14 flex-none"/>
                                <p className="">Chrome Android en IOS</p>
                            </div>
                            <div onClick={() => openInNewTab(metadata.permissionsSafariPath)} className="flex flex-row items-center space-x-5 bg-persona p-2 rounded-lg shadow-lg bg-opacity-80 hover:bg-opacity-100 cursor-pointer">
                                <FaSafari className="h-14 w-14 flex-none"/>
                                <p className="">Safari IOS</p>
                            </div>
                        </div>
                        <p className="leading-relaxed mt-3">Of gebruik de knoppen hieronder om het spel zonder camera te spelen!</p>
                    </details>
                </div>
            }

            {/*<div className="bg-red-700 text-white text-center">{scanError}</div>*/}
            <div className="bg-persona-greenDark text-white text-center w-full break-all">{scanResult}</div>

            {/* Debug dropdown ---------------------------------- */}
            { (scanError || process.env.REACT_APP_DEBUG_MODE === 'true' || auth.isAuthorized(7) == true) &&
                <>
                    <div className="w-full flex flex-wrap pt-4 justify-center gap-2 flex-grow items-center content-start">
                        {
                            btnImages.map((item, key) => {
                                // const xOffset = `${item.offsetX < 0 ? '-' : ''}left-${Math.abs(item.offsetX)}`;
                                // const yOffset = `${item.offsetY < 0 ? '-' : ''}top-${Math.abs(item.offsetY)}`;
                                return (
                                    <div key={key} className="w-36 rounded-md persona-btn persona-btn-hover cursor-pointer h-36 overflow-hidden"
                                         onClick={() => onSelectButton(item)}>
                                        <img className={`absolute `} style={{top: `${item.offsetY}`, left: `${item.offsetX}`}} src={item.img} />
                                        <p className="absolute bottom-0 left-0 text-center text-xl bg-persona-white w-full">{item.label}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            }

            <div className="pb-24 pt-5">
                <div
                    className="bg-persona-greenLight rounded-full mx-auto w-20 h-20 flex items-center shadow-lg cursor-pointer"
                    onClick={() => history.goBack()}>
                    <RiArrowGoBackLine className="mx-auto h-12 w-12 m-8 text-persona-white" />
                </div>
            </div>


        </div>
    )
}

export default ScanScreen