import React, {useEffect, useState} from 'react'
import useDebugStore from "../../Stores/debugStore";
import {globalVariables, overrideGlobalVariables, resetDialogHistory} from '../../jsonData/DataWrangler';

import { MdDeleteForever, MdShare } from "react-icons/md";

const SaveStateManager = () => {
    
    const [saveStates, addSaveState, deleteSaveState, getSaveState] = useDebugStore(state => [state.saveStates, state.addSaveState, state.deleteSaveState, state.getSaveState])

    const [inputName, setInputName] = useState('');
    
    useEffect(() => {
        addSaveState(globalVariables, 'default');
    }, [])
    
    const addNewSaveState = (name) => {
        if (name.length <= 0)
            return;
        
        // try to parse the input
        try {
            const parsed = JSON.parse(name);
            addSaveState(parsed.globalVars, parsed.name);
            setInputName('');
            
            return;
            
        } catch(e) {
            // error
        }
        
        const globalVarsCopy = JSON.parse(JSON.stringify(globalVariables));
        
        addSaveState(globalVarsCopy, name);
        setInputName('');
    }

    const deleteSave = (name) => {
        
        deleteSaveState(name);
    }

    const loadSaveState = (name) => {
        const saveState = getSaveState(name);

        if (saveState == null) {
            console.log("Savestate is null!")
            return;
        }
        
        if (saveState.globalVars == null || saveState.globalVars.length == 0) {
            console.log('Global variables youre trying to load are null or empty!');
            return;
        }

        resetDialogHistory();

        overrideGlobalVariables(saveState.globalVars);
    }
    
    const copyToClipboard = ( toCopy ) => {
        navigator.clipboard.writeText(toCopy).then(function() {
            console.log("Copied to clipboard: ", toCopy);
        }, function() {
            /* clipboard write failed */
        });
    }

    return (
        <div className="flex flex-col bg-white bg-opacity-40 fixed right-0 top-0 w-72 p-3 rounded-bl-lg shadow-lg">
            <p className="text-lg text-center">Save States</p>
            <details>
                <summary>Click for info</summary>
                <p className="text-xs opacity-70 mb-1">Add a new save state by typing a name of your choosing and clicking 'add new'.</p>
                <p className="text-xs opacity-70 mb-3">You can share a save state with someone else by using the share button next to it. Paste the copied content in the field and click 'add new'.</p>
                
            </details>
            <div className="flex flex-row">
                <input
                    type='text'
                    className="w-0 flex-grow"
                    value={inputName}
                    placeholder='save-name'
                    onChange={(e) => setInputName(e.target.value)}/>
                <button className="bg-persona-greenMiddle hover:bg-persona text-persona-white px-2 flex-none"
                    onClick={() => addNewSaveState(inputName)}>
                    Add new
                </button>
            </div>
            <div className="flex flex-col gap-1 pt-2">
                {
                    saveStates.map((item, key) => {
                        return (
                            <div key={key} className="bg-persona-greenLight text-persona-white rounded-md p-0.5 gap-2 flex flex-row items-center justify-items-stretch">
                                <div className="flex-grow h-8 bg-persona-white hover:bg-persona cursor-pointer hover:text-persona-white text-sm text-persona rounded-md px-3 items-center flex flex-row" onClick={() => loadSaveState(item.name)}>
                                    <p className="mr-2 opacity-70">Load: </p><p className="font-semibold">{item.name}</p>
                                </div>

                                <MdShare className="flex-none w-6 h-6 hover:text-persona-greenMiddle cursor-pointer"
                                     onClick={() => copyToClipboard(JSON.stringify(getSaveState(item.name)))}/>
                                
                                <MdDeleteForever className="flex-none w-6 h-6 hover:text-persona-greenMiddle cursor-pointer"
                                    onClick={() => deleteSave(item.name)}/>
                            </div>
                        )
                    })
                }
            </div>
            
        </div>
    )
}

export default SaveStateManager