import React from 'react'

const BackgroundDecorations = () => {
    return (
        <div className="fixed top-0 left-0 w-screen h-screen bg-gradient-to-b from-persona-greenLight to-persona-greenDark">
            <div className="absolute h-64 w-64 bg-persona-white opacity-22 rounded-full -right-36 -top-32"></div>
            <div className="absolute h-72 w-72 bg-persona-white opacity-22 rounded-full -right-44 top-16"></div>
            <div className="absolute h-72 w-72 bg-persona-white opacity-22 rounded-full -left-44 -bottom-44"></div>
        </div>
    )
}

export default BackgroundDecorations
