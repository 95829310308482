var fs = require('fs');
var archiver = require('archiver');
var metadata = require('../metadata.json');
var env = require('dotenv').config();

var path = require('path');
var root = path.resolve("./");

if (env.REACT_APP_CREATE_BACKUP !== false)
{
    const cleanedTitle = metadata.title.replace(' ', '-');

    const folderToZip = path.join(root, 'build');

// create destination folder if not available
    const destinationFolder = path.join(root, 'build-backups', cleanedTitle);
    if (!fs.existsSync(destinationFolder)) {
        fs.mkdirSync(destinationFolder, { recursive: true });
    }

    const outputFilename = path.join(destinationFolder, (cleanedTitle + "_" + metadata.buildMajor + "-" + metadata.buildMinor + ".zip"));

    var outputFile = fs.createWriteStream(outputFilename);
    var archive = archiver('zip');

    outputFile.on('close', function () {
        console.log('Backup complete!');
        console.log('Final size of zip: ' + (archive.pointer() / (1024 * 1024)).toFixed(2) + ' megabytes');
        console.log('Backup file written to: [ ' + outputFilename + ' ]');
        console.log('Successfully built version [ ' + metadata.buildMajor + '.' + metadata.buildMinor + ' ]');
    });

    archive.on('warning', function (err) {
        if (err.code === 'ENOENT') {
            console.log("warning: " + err);
        } else {
            throw err;
        }
    });

    archive.on('error', function (err) {
        throw err;
    })

    archive.pipe(outputFile);

// add build folder
    archive.directory(folderToZip, false)
// add current metadata.json to it
    archive.file(path.join(root, 'src', 'metadata.json'), { name: 'metadata.json' });

    archive.finalize();
}