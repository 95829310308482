import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './App.css';
import Main from './components/Main';
import MissionUpdate from './components/MissionUpdate';
import { loadDataFromJson } from './jsonData/DataWrangler';

import BackgroundDecorations from './components/BackgroundDecorations';
import { ImSpinner2 } from 'react-icons/im';
import { useGeneralStore } from "./Stores/store";
import { useUserStore } from "./Stores/userStore";
import { useFeedbackStore } from "./Stores/feedbackStore";
import Popup from "./components/Popup";
import NoteEditor from "./components/debug/NoteEditor";
import Inventory from "./components/Inventory";
import CookieModal from "./components/CookieModal";
import SaveStateManager from "./components/debug/SaveStateManager";

import Confetti from 'react-confetti';
import useWindowDimensions from "./Misc/UseWindowDimensions";
import {useAuth} from "./Auth/auth";

const App = () => {

  const auth = useAuth();
  const [data, setLoadedData] = useState();
  const [loading, setLoading] = useState(true);

  // very important! Loads the data from either the localstorage or the original file.
  useEffect(async() => {

    setLoading(true);

    await FetchData();

    await auth.isSignedIn();

    setLoading(false);

  }, [])

  const FetchData = async () => {

    const response = await fetch(`${document.location.origin}/data/data.json`
        , {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }
    )
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    setLoadedData(loadDataFromJson(data));

    if (process.env.REACT_APP_LOG_TO_CONSOLE)
      console.log(`SUCCES: Loaded data from ${document.location.origin}/data/data.json`);
  }

  let history = useHistory();

  const [currentScene, setCurrentScene] = useState(null);

  const changeScene = (newScene) => {

    if (newScene.sceneType === '/') {
      history.replace('/');
    }
    else if (newScene.sceneType === 'scan') {
      history.replace('/scan');
    }
    else if (newScene.sceneType === 'scene') {
      if (currentScene === null || currentScene.sceneType !== newScene.sceneType)
        history.replace('/scene');
    }

    setCurrentScene(newScene);
  }

  const resetCurrentScene = () => {
    setCurrentScene(null);
  }

  const [pendingMissionUpdates, updatePendingMissionUpdates] = useState([]);

  const addMissionUpdate = (missionUpdate) => {

    if (pendingMissionUpdates.some(update => update.Properties.Id === missionUpdate.Properties.Id)) {
      //console.log("Exists already!");
      return;
    }

    updatePendingMissionUpdates(previousArr => [...previousArr, missionUpdate]);
  }

  const dismissMissionUpdate = (missionUpdate) => {

    const index = pendingMissionUpdates.indexOf(missionUpdate);
    if (index > -1) {
      updatePendingMissionUpdates(pendingMissionUpdates.filter((entry) => entry !== missionUpdate));
    }
  }

  // user
  const [user] = useUserStore(state => [state.user]);
  
  // popups
  const popup = useGeneralStore(state => state.shownPopup);
  const [editableNote] = useFeedbackStore(state => [state.editableNote]);
  
  // errors
  const shownError = useGeneralStore(state => state.shownError);
  const showError = useGeneralStore(state => state.showError);
  const removeError = useGeneralStore(state => state.removeError);
  
  // inventory
  const inventoryShown = useGeneralStore(state => state.inventoryShown)
  const inventoryItems = useGeneralStore(state => state.inventoryItems);
  
  const celebrationEnabled = useGeneralStore(state => state.celebrationEnabled);
  
  const { height, width } = useWindowDimensions();
  
  const dismissError = () => {
    removeError();
  }

  if (!data || loading) {
    return (
        <div className="max-w-screen-sm m-auto">
          <div className="relative mt-32 h-full mx-auto z-50 flex flex-col items-center space-y-5">
            <p className="mt-32 font-header text-3xl text-center text-persona-white animate-pulse">Loading</p>
            <ImSpinner2 className="w-24 h-24 animate-spin text-persona-white"/>
          </div>
          <BackgroundDecorations />
        </div>
    )
  }
  else {
    return (
      <>
        {
            celebrationEnabled &&
            <Confetti
                width={width}
                height={height}
                numberOfPieces={600}
                gravity={0.02}
            />
        }
        
        <Main onChangeScene={changeScene} resetCurrentScene={resetCurrentScene} addMissionUpdate={addMissionUpdate} />

        <MissionUpdate pendingMissionUpdates={pendingMissionUpdates} dismissMissionUpdate={dismissMissionUpdate} />
        
        {
          shownError &&
              <div className="z-50 w-full py-2 bg-opacity-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                onClick={dismissError}>
                <div className="bg-red-800 text-white text-center">Error encountered: {shownError}</div>
              </div>
        }
        
        {
          popup &&
            <Popup details={popup}/>
        }

        {
          editableNote &&
            <NoteEditor editableNote={editableNote}/>
        }

        {
          inventoryShown &&
            <Inventory inventoryItems={inventoryItems}/>
        }

        {
          user.prompted === false && process.env.REACT_APP_DISPLAY_COOKIE_MODAL === "true" &&
            <CookieModal/>
        }

        {
          process.env.REACT_APP_SAVE_STATES === "true" || auth.isAuthorized(7) == true &&
            <SaveStateManager/>
        }
      </>
    )
  }
}

export default App;
