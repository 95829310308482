import React from 'react';

const PersonaSelector = () => {

    const common = "bg-persona-dark rounded-lg shadow-md flex flex-row text-center h-32 items-center transition hover:shadow-lg hover:bg-persona";

    return (
        <div className="px-5 pb-16 pt-4 flex flex-col bg-persona-white h-screen space-y-5">

            <div className="text-center text-3xl pt-4 text-persona-dark">Persona Selector</div>

            <div className={`${common} bg-cover bg-center bg-sleeping-man`}>
                <div className="w-full text-center text-persona-white text-2xl">
                    one
                </div>
            </div>

            <div className={`${common} bg-cover bg-center bg-sleeping-man`}>
                <div className="w-full text-center text-persona-white text-2xl">
                    two
                </div>
            </div>

            <div className={`${common} bg-cover bg-center bg-sleeping-man`}>
                <div className="w-full text-center text-persona-white text-2xl">
                    three
                </div>
            </div>

            <div className={`${common} bg-cover bg-center bg-sleeping-man`}>
                <div className="w-full text-center text-persona-white text-2xl">
                    four
                </div>
            </div>
        </div >
    )
}

export default PersonaSelector
