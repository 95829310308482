import React, { useState, useEffect } from 'react'
import useFeedbackStore from "../../Stores/feedbackStore";

import exportFromJSON from 'export-from-json'
import {AiOutlineCloseCircle} from "react-icons/ai";
import {useAuth} from "../../Auth/auth";

const NoteEditor = ({ editableNote }) => {

    const setEditableNote = useFeedbackStore(state => state.setEditableNote);
    const submitNote = useFeedbackStore(state => state.submitNote);
    const deleteNote = useFeedbackStore(state => state.deleteNote);
    
    const [toEdit, updateToEdit] = useState(editableNote);
    const [editable, setEditable] = useState(false);
    
    const auth = useAuth();
    
    const editText = (e) => {
        updateToEdit({ ...toEdit, edited_text: e.target.value });
    }
    
    const editComment = (e) => {
        updateToEdit({ ...toEdit, comments: e.target.value });
    }
    
    const submitNoteToStore = async (toEdit) => {
        
        await auth.isSignedIn();
        submitNote(toEdit);
    }

    return (
        <>
            {toEdit &&
                <div className="bg-black bg-opacity-70 absolute w-screen h-screen top-0 left-0 flex z-20">
                    <div className="bg-persona rounded-3xl shadow-lg m-auto mx-auto py-5 px-6 flex flex-col w-full max-w-lg relative
                border-2 space-y-2">
                        <div className="font-semibold text-persona-white flex-grow-0 text-sm">Aantekening #{toEdit.index} - {toEdit.node_index}</div>
                        <div className="flex flex-row items-center">
                            <p className="font-semibold flex-grow text-lg text-white text-opacity-50">Originele tekst</p>
                            <label className="flex items-center text-white text-opacity-50">
                                Tekst aanpassen<input type={"checkbox"} className="ml-2" defaultChecked={editable} onChange={() => setEditable(!editable)}/>
                            </label>
                        </div>
                        <div className="text-sm text-opacity-80 text-persona-white flex-grow">{toEdit.original_fragment.Properties.Text}</div>
                        {
                            editable && 
                            <>
                                <p className="font-semibold flex-grow text-lg text-white text-opacity-50">Bewerkte tekst</p>
                                <textarea className="block w-full px-3 py-1.5 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded m-0"
                                          rows="6"
                                          value={toEdit.edited_text}
                                          onChange={ (e) => editText(e) }
                                />
                            </>
                        }

                        <p className="font-semibold text-white text-opacity-50 text-lg">Commentaar</p>
                        <textarea className="block w-full px-3 py-1.5 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded m-0"
                          rows="3"
                          value={toEdit.comments}
                          onChange={ (e) => editComment(e) }
                        />

                        <div onClick={() => setEditableNote(null) } className="bg-persona-greenLight shadow-md absolute right-2 top-0 h-10 w-10 cursor-pointer rounded-full text-white">
                            <AiOutlineCloseCircle className="h-full w-full p-1"/>
                        </div>

                        <div className="flex flex-wrap gap-2">
                            {/*<div onClick={() => deleteNote(toEdit) } className="flex-grow bg-red-500 text-white px-5 cursor-pointer rounded-lg my-auto py-2">*/}
                            {/*    Delete*/}
                            {/*</div>*/}
                            <div onClick={() => submitNoteToStore(toEdit) }  className="flex-grow bg-green-500 text-white px-5 cursor-pointer rounded-lg my-auto py-2">
                                Submit
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
            }
        </>
        
    )
}

export default NoteEditor