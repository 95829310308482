// table to be used for converting legacy codes to the obviously more superior ones

export const legacyQRDictionary = [
    // Veilig werken legacy
    { originalValue: 'QR code 1', convertedValue: '0776' },
    { originalValue: 'QR code 2', convertedValue: '077D' },
    { originalValue: 'QR code 3', convertedValue: '0753' },
    { originalValue: 'QR code 4', convertedValue: '074C' },
    { originalValue: 'QR code 5', convertedValue: '0745' },
    { originalValue: 'QR code 6', convertedValue: '0768' },
    { originalValue: 'QR code 7', convertedValue: '0761' },
    { originalValue: 'QR code 8', convertedValue: '076F' },
    { originalValue: 'QR code 9', convertedValue: '072B' },
    { originalValue: 'QR code 10', convertedValue: '075A' },
    { originalValue: 'QR code 11', convertedValue: '0737' },
    { originalValue: 'QR code 12', convertedValue: '073E' },

    // Cyber Security legacy
    { originalValue: 'Spy_code_1', convertedValue: '01DB' },
    { originalValue: 'Spy_code_2', convertedValue: '01E1' },
    { originalValue: 'Spy_code_3', convertedValue: '01E7' },
    { originalValue: 'Spy_code_4', convertedValue: '03E8' },
    { originalValue: 'Spy_code_5', convertedValue: '03EE' },
    { originalValue: 'Spy_code_6', convertedValue: '03F4' },
    { originalValue: 'Spy_code_7', convertedValue: '03FA' },
    { originalValue: 'Spy_code_8', convertedValue: '0400' },
    { originalValue: 'Spy_code_9', convertedValue: '0406' },
    { originalValue: 'Spy_code_10', convertedValue: '040C' },
    { originalValue: 'Spy_code_11', convertedValue: '0412' },
    { originalValue: 'Spy_code_12', convertedValue: '0418' },
    { originalValue: 'Spy_code_13', convertedValue: '041E' },
    { originalValue: 'Spy_code_14', convertedValue: '0424' },
    { originalValue: 'Spy_code_15', convertedValue: '042A' },
    { originalValue: 'Spy_code_16', convertedValue: '0430' },
];