import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { getVariableWithName, getVariableWithNamespace } from "../jsonData/DataWrangler";
import { MdExpandMore, MdExpandLess } from 'react-icons/md'

import { RiArrowGoBackLine, RiCheckLine } from 'react-icons/ri';
import metadata from './../metadata.json';
import produce from "immer";

const Collectables = () => {
    
    let history = useHistory();
    const collectablesName = getVariableWithName('GameSettings', 'collectablesName').Value;

    const [collectables, setCollectables] = useState(getVariableWithNamespace(collectablesName).Variables);

    const [collectablesVisibility, setCollectablesVisibility] = useState(getVariableWithNamespace('VisibilityCollectables')?.Variables)

    const [collectedCount, setCollectedCount] = useState(0);

    useEffect(() => {
        setCollectedCount(collectables.filter(e => e.Value.toLowerCase() === 'true').length);
    }, [collectables])

    const [inventories, setInventories] = useState(null);
    const [inventoryStates, setInventoryStates] = useState(null);

    useEffect(() => {
        if (collectablesVisibility == null || collectablesVisibility.length <= 0)
            return;

        const invs = [];
        const invStates = [];

        for (let i = 0; i < collectablesVisibility.length; i++) {
            if (collectablesVisibility[i].Value.toLowerCase() === "false")
                continue;

            const inv = getVariableWithNamespace(collectablesVisibility[i].Variable)?.Variables;

            if (inv != null) {
                inv.name = collectablesVisibility[i].Description;
                invs.push(inv)

                invStates.push({
                    name: collectablesVisibility[i].Description,
                    open: false,
                });
            }
        }

        setInventories(invs);
        setInventoryStates(invStates);
    }, []);


    const toggleInventoryOpen = (inventory) => {

        const updated = produce(inventoryStates, draft => {

            const index = draft.findIndex(inv => inv.name === inventory.name);

            if (index !== -1) {
                draft[index].open = !inventoryStates[index].open;
            }
        })
        setInventoryStates(updated)
    }

    return (
        <div className="px-5 pb-24 pt-4 flex flex-col h-screen relative">
            <div className="flex items-center w-full h-24 text-xl">
                <p className="w-full text-3xl text-center font-header text-persona-white tracking-wide capitalize">
                    {collectablesName}
                </p>
            </div>
            <div className="h-full overflow-y-auto mb-5 px-5 py-5 bg-persona-white rounded-2xl shadow-md text-sm space-y-4">
                
                {
                    inventories?.length > 0 ? inventories.map((inventory, inventoryKey) => {

                            return (
                                inventoryStates[inventoryKey].open === false
                                    ?
                                    <div key={inventoryKey} className="bg-persona-greenLight text-persona-white p-3 rounded-lg shadow-lg cursor-pointer" onClick={() => {
                                        toggleInventoryOpen(inventory);
                                    }}>
                                        <div className="flex flex-row items-center space-x-5">
                                            <p className="text-xl font-bold flex-grow">{inventory.name}</p>
                                            <p className="text-xl">{inventory.filter(item => item.Value.toLowerCase() === 'true' || parseInt(item.Value.toLowerCase()) > 0).length} / {inventory.length}</p>
                                            <MdExpandLess className="w-8 h-full"/>
                                        </div>
                                    </div>
                                    :
                                    <div key={inventoryKey} className="flex flex-col bg-persona-greenLight text-persona-white p-3 rounded-lg shadow-lg cursor-pointer"
                                         onClick={() => {
                                             toggleInventoryOpen(inventory);
                                         }}>

                                        <div className="flex flex-row items-center mb-5 space-x-5">
                                            <p className="text-2xl font-bold flex-grow">{inventory.name}</p>
                                            <p className="text-xl">{inventory.filter(item => item.Value.toLowerCase() === 'true' || parseInt(item.Value.toLowerCase()) > 0).length} / {inventory.length}</p>
                                            <MdExpandMore className="w-8 h-full"/>
                                        </div>
                                        {
                                            inventory?.length > 0 && inventory.map((item, itemKey) => {
                                                return <div key={itemKey} className={`flex flex-row p-2 items-center text-lg ${itemKey % 2 === 0 && "bg-white bg-opacity-20"}`}>

                                                    <p className="flex-none w-8">{itemKey + 1}</p>
                                                    <p className="flex-grow">{item.Description}</p>
                                                    <div className="bg-persona-white flex-none w-8 h-8 rounded-full ml-2">
                                                        {
                                                            (item.Value.toLowerCase() === 'true' || parseInt(item.Value.toLowerCase()) > 0) &&
                                                            <RiCheckLine className="text-persona-greenDark w-full h-full"/>
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                            )
                        }) :
                        <div className="py-10">
                            <p className="text-center text-lg">Tot nu toe heb je geen {collectablesName.toLowerCase()} gevonden.</p>
                            <p className="text-center text-lg">Blijf goed zoeken en kom later terug.</p>
                        </div>
                }

            </div>

            <div className="bg-persona-greenLight rounded-full mx-auto w-20 h-20 flex items-center shadow-lg cursor-pointer" onClick={() => history.goBack()}>
                <RiArrowGoBackLine className="mx-auto h-12 w-12 m-8 text-persona-white" />
            </div>
        </div>
    )
}

export default Collectables

export const CollectableItem = ({ collectable, index }) => {

    return (
        <div className="flex flex-row items-center h-auto py-2 rounded-xl shadow-md bg-persona-greenLight text-persona-greenDark">
            <p className="flex-0 text-xl text-center w-12 font-bold">{index + 1}</p>
            <p className="flex-1 text-xl ml-2 mr-2">{collectable.Description}</p>
            <div className="mr-2 flex-grow-0 h-10 w-10 rounded-full bg-persona-white border-2 border-persona-greenDark">
                {
                    collectable.Value.toLowerCase() === 'true' ?
                        <RiCheckLine className="w-full h-full" /> :
                        null
                }
            </div>
        </div>
    )
}
