import React, { useState } from 'react'
import {
    determineLocationAndContext
} from '../../jsonData/DataWrangler';
import useGeneralStore from "../../Stores/store";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";

const WhatsappHeader = ({ currentNode }) => {
    let nodeId = currentNode.Properties.Id;
    
    let { location, locationImage, context } = determineLocationAndContext(nodeId);
    let imagePath = '';
    
    const [headerImagePath, headerText, fontSize, increaseFontSize, decreaseFontSize] = useGeneralStore(state => [state.headerImagePath, state.headerText, state.fontSize, state.increaseFontSize, state.decreaseFontSize]);

    let img = null;
    
    if (headerImagePath != null)
        img = require(`../../${headerImagePath}`).default;
    else if (locationImage != null)
        img = require(`../../${locationImage}`).default;

    return (

        <div className="flex flex-row px-3 py-2">
            <div className="h-20 mr-4 my-auto">
                {
                    img &&
                    <div className="rounded-full w-20 h-full border-2 border-persona-white overflow-hidden">
                        {
                            img != null && <img src={img} alt={imagePath} className="bg-contain" />
                        }
                    </div>
                }
            </div>
            <div className="flex-col flex-1 my-auto text-persona-white">
                <div className="m-auto text-4xl font-bold font-header">{headerText}</div>
                <div className="m-auto text-sm">{context}</div>
            </div>
            <div className="flex flex-row items-center">
                <AiOutlineZoomOut onClick={() => decreaseFontSize()} className="w-16 h-full px-2 text-white cursor-pointer opacity-50 hover:opacity-100"/>
                <p className="text-white text-opacity-70 text-sm">{fontSize.toFixed(1)}</p>
                <AiOutlineZoomIn onClick={() => increaseFontSize()} className="w-16 h-full px-2 text-white cursor-pointer opacity-50 hover:opacity-100"/>
            </div>
        </div>
    );

}

export default WhatsappHeader;