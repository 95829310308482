import React from 'react';
import { useHistory } from "react-router-dom";
import {getNodeWithExternalId, getVariableWithName, getVariableWithNamespace} from "../jsonData/DataWrangler";

import { RiArrowGoBackLine, RiContactsBookLine } from 'react-icons/ri';
import SendProfileEmail from './SendProfileEmail';
import {log} from "@craco/craco/lib/logger";
import useUserStore from "../Stores/userStore";

function getKennisVragenDict(kennisvragen) {

    const vars = getVariableWithNamespace('kennisvragen').Variables;
    const statNames = getVariableWithNamespace('GameSettings').Variables.filter(setting => setting.Variable.includes('statName'));

    let dict = [];

    statNames.forEach(stat => dict.push({ key: stat.Value, value: [] }));

    for (let i = 0; i < vars.length; i++) {
        if (vars[i].Description === "")
            continue;
        
        dict.filter(di => di.key === vars[i].Description)[0]?.value.push(vars[i]);
    }

    return dict;
}

const ProfileScreen = () => {

    let history = useHistory();

    const profileHeaderText = getNodeWithExternalId('profileHeaderName')?.Properties.Text || 'Profiel';

    const statName = getVariableWithName("GameSettings", "statName1").Value;
    const statScore = getVariableWithName("playerproperties", statName);

    const profielScore = getVariableWithName("playerproperties", "profielscore");

    // collectables
    const collectablesName = getVariableWithName("GameSettings", "collectablesName").Value;
    const collectables = getVariableWithNamespace(collectablesName);
    const foundCollectables = collectables.Variables.filter(entry => entry.Value.toLowerCase() === 'true').length;

    let collectablesFoundPercentage = 100 / collectables.Variables.length * foundCollectables;

    // user
    const [user] = useUserStore(state => [state.user]);

    // eindvragen
    const eindvragen = getVariableWithNamespace('eindvragen');

    // kennisvragen
    let kennisVragenDict = getKennisVragenDict('kennisvragen');

    let profileCombined = {
        statScore: statScore ? statScore.Value : 0,
        profielScore: profielScore ? profielScore.Value : 0,
        collectables: collectables.Variables,
        foundCollectables: foundCollectables,
        eindvragen: eindvragen ? eindvragen.Variables : [],
        kennisvragen: kennisVragenDict ? kennisVragenDict : [],
    }

    function sortKennisvragen(a, b) {

        const intA = parseInt(a.Variable.replace('vraag', ''));
        const intB = parseInt(b.Variable.replace('vraag', ''));

        if (intA < intB)
            return -1;
        if (intA > intB)
            return 1;
        return 0;
    }

    return (
        <div className="pt-4 flex flex-col h-screen relative">

            <div className="flex items-center w-full h-20 text-xl">
                <p className="w-full text-3xl text-center text-persona-white font-header tracking-wide">
                    { profileHeaderText }
                </p>
            </div>

            <div
                className="text-lg mx-5 font-header text-persona-greenLight h-full py-2 overflow-y-auto no-scrollbar space-y-3">

                {/* Eindvragen */}
                {eindvragen && <div className="w-full p-2 rounded-xl bg-persona-white shadow-lg">
                    <div className="flex flex-row">
                        <p className="w-32 flex-none my-auto mx-2">Eindvragen</p>
                        <div className="flex flex-wrap gap-2">
                        {eindvragen.Variables.map((eindVraag, key) => {
                            return <VraagVisual key={key} entry={eindVraag} />
                        })}
                        </div>
                    </div>
                </div>}

                {/*Kennisvragen*/}
                {kennisVragenDict &&
                    kennisVragenDict.map((kennisVraag, key) => {
                        return (
                            <div key={key} className="w-full p-2 rounded-xl bg-persona-white shadow-lg">
                                <div className="flex flex-row">
                                    <p className="w-32 flex-none my-auto mx-2">{kennisVraag.key}</p>
                                    <div className="flex flex-wrap gap-2">
                                    {kennisVraag.value.map((vraag, key) => (
                                        <VraagVisual key={key} entry={vraag} />
                                    ))}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {statScore ?
                    <div className="relative p-2 px-4 bg-persona-white rounded-xl shadow-lg">
                        <div className="flex flex-row">
                            <p className="w-1/2 my-auto mx-2">{statName}</p>
                            <p className="my-auto">{statScore.Value}</p>
                        </div>
                    </div> :
                    null
                }

                <div className="relative p-4 pt-2 bg-persona-white rounded-xl shadow-lg">
                    <p className="mr-5 mb-1 text-xl">Gevonden {collectablesName}</p>
                    <div
                        className="relative overflow-hidden h-8 text-sm flex flex-row items-center rounded bg-persona-incompleteMissionText">
                        <div style={{ width: `${collectablesFoundPercentage}%` }}
                            className="shadow-none flex flex-col h-full text-center whitespace-nowrap text-persona-white justify-center bg-gradient-to-r from-persona-greenDark to-persona">
                        </div>
                        <p className="absolute w-full text-center text-persona-white text-lg font-semibold font-sans">{foundCollectables} / {collectables.Variables.length}</p>
                    </div>
                </div>

                <SendProfileEmail profileData={profileCombined} />
            </div>
            
            <div
                className="bg-persona-greenLight my-5 rounded-full mx-auto w-20 h-20 flex items-center shadow-lg cursor-pointer mb-12"
                onClick={() => history.goBack()}>
                <RiArrowGoBackLine className="mx-auto h-12 w-12 m-8 text-persona-white" />
            </div>

            {
                user.uuid !== "" &&
                    <div className="absolute bottom-4 w-full text-white text-center text-xs">
                        {user.allowedCookies ? "y" : "n"} - {user.uuid.toString()}</div>
            }
        </div>
    )
}

export default React.memo(ProfileScreen)


export const VraagVisual = ({ entry }) => {


    function answeredStatus(value, variable) {

        // handle booleans
        if (entry.Type === "Boolean") {

            if (value.toLowerCase() === 'true') {
                return <div className="h-8 bg-persona-correct rounded-sm"/>
            } else {
                return <div className="h-8 bg-persona-incompleteMissionText rounded-sm"/>
            }
        }
        // handle integers
        else if (entry.Type === "Integer") {

            switch (parseInt(value)) {
                case 0:
                    return <div className="h-8 bg-persona-incompleteMissionText rounded-sm"/>
                case 1:
                    return <div className="h-8 bg-persona-correct rounded-sm"/>
                case 2:
                    return <div className="h-8 bg-persona-incorrect rounded-sm"/>
                case 3:
                    return <div className="h-8 bg-persona-incompleteMissionText rounded-sm"/>
                case 4:
                    return <div className="h-8 bg-persona-orange rounded-sm"/>
                default:
                    return <div className="h-8 bg-persona-incompleteMissionText rounded-sm"/>
            }
        }
    }

    return (
        <div className="w-8 text-center">
            <div className="w-8">
                {
                    answeredStatus(entry.Value, entry.Variable)
                }
            </div>
        </div>
    )
}

