import React, { useState } from 'react'
import { globalVariables, updateGlobalVar } from "../../jsonData/DataWrangler";
import { MdExpandMore, MdExpandLess } from 'react-icons/md'
import { useHistory } from "react-router-dom";
import { RiArrowGoBackLine } from "react-icons/ri";

const ArticyVariablesDisplay = () => {

    let history = useHistory();
    
    const [globalVars, setGlobalVars] = useState(globalVariables);
    
    const [expandedArr, setExpandedArr] = useState(new Array(globalVars.length).fill(false));
    
    const toggle = (index) => {
        setExpandedArr(arr=>({
            ...arr,
            [index]: !arr[index]
        }))
    }

    const renderNestedArrays = (i, key, trail) => {
        
        return (
            <div key={key} className="rounded-lg bg-persona">
                {
                    i.hasOwnProperty("Variables") ?
                        <div className="text-white p-3 rounded-lg">
                            {
                                i.Namespace &&
                                <div className="text-xl font-bold mb-2 flex flex-row items-center cursor-pointer"

                                     onClick={() => { toggle(globalVars.indexOf(i))}}>
                                    <p className="flex-grow select-none">{i.Namespace}</p>
                                    {expandedArr[globalVars.indexOf(i)] == true ?
                                        <MdExpandMore
                                            className="w-8 h-full"/> : 
                                        <MdExpandLess
                                            className="w-8 h-full"/>
                                    }
                                </div>
                            }
                            {
                                expandedArr[globalVars.indexOf(i)] == true &&
                                    i.Variables.map((item, key) => {
                                        !trail.includes(i.Namespace) && trail.push(i.Namespace);
                                        return renderNestedArrays(item, key, trail)
                                    })
                            }
                        </div> :
                        renderEndPoint(i, key, trail)
                }
            </div>
        )
    }
    
    const updateVariable = (key, newValue, trail) => {

        setGlobalVars(updateGlobalVar(key, newValue, trail));
    }
    
    const renderEndPoint = (item, index, trail) => {

        switch (item.Type) {
            case "Boolean": 
                return (
                    <div key={index} className="bg-gray-50 bg-opacity-10 mb-1 text-persona-white text-sm flex flex-row p-2 rounded-md">
                        <div className="flex flex-row flex-grow items-center">
                            <p className="mr-5 font-semibold">{item.Variable}</p>
                            <p className="flex-grow mr-4 text-sm">{item.Description}</p>
                        </div>
                        <input
                            type="checkbox"
                            checked={(item.Value.toLowerCase() === 'true')}
                            onChange={(e) => {
                                updateVariable(item.Variable, e.target.checked, trail);
                            }}
                            className="flex-grow-0"/>
                    </div>
                )
                
            case "String":
                return (
                    <div key={index} className="bg-gray-50 bg-opacity-10 mb-1 text-persona-white text-sm flex flex-row p-2 items-center rounded-md">
                        <p className="mr-5 font-semibold">{item.Variable}</p>
                        <p className="flex-grow mr-4 text-sm">{item.Description}</p>
                        <input
                            type="text"
                            value={item.Value}
                            onChange={(e) => {
                                setGlobalVars(updateGlobalVar(item.Variable, e.target.value, trail));
                            }}
                            className="bg-gray-200 rounded-md border-2 border-gray-400 p-1 text-persona-greenDark text-center w-full text-right"/>
                    </div>
                )
                
            case "Integer":
                return (
                    <div key={index} className="bg-gray-50 bg-opacity-10 mb-1 text-persona-white text-sm flex flex-row p-2 items-center rounded-md">
                        <p className="mr-5 font-semibold">{item.Variable}</p>
                        <p className="flex-grow mr-4 text-sm">{item.Description}</p>
                        <input
                            type="number"
                            value={item.Value}
                            onChange={(e) => {
                                setGlobalVars(updateGlobalVar(item.Variable, e.target.value, trail));
                            }}
                            className="bg-gray-200 rounded-md border-2 border-gray-400 p-1 text-persona-greenDark text-center w-20"/>
                    </div>
                )
        }
    }

    return (
      <div className="px-5 py-16 flex flex-col relative">
          <div className="flex flex-col space-y-2">
              {
                  globalVars.map((item, key) => {
                      return renderNestedArrays(item, key, []);
                  })
              }
          </div>
          <div
              className="bg-persona-greenLight my-5 rounded-full mx-auto w-20 h-20 flex items-center shadow-lg cursor-pointer"
              onClick={() => history.goBack()}>
              <RiArrowGoBackLine className="mx-auto h-12 w-12 m-8 text-persona-white" />
          </div>
      </div>
    )
}

export default ArticyVariablesDisplay