import React from 'react'
import useUserStore from "../Stores/userStore";

const CookieModal = () => {
    
    const [setCookieAllowed] = useUserStore(state => [state.setCookieAllowed]);
    
    const setCookieAcceptance = (allowed) => { setCookieAllowed(allowed); }
    
    return (
        <div className="bg-black bg-opacity-70 absolute w-screen h-screen top-0 left-0 flex z-20">
            <div className="bg-persona rounded-3xl shadow-lg m-auto mx-auto py-4 px-6 flex flex-col w-full max-w-lg relative
                border-2 space-y-2">
                <p className="text-center text-2xl text-persona-white pt-5">
                    Wilt u cookies accepteren?
                </p>
                <div className="flex flex-row gap-2">
                    <div className="bg-persona-deny font-semibold cursor-pointer bg-opacity-80 hover:bg-opacity-100 hover:shadow-lg text-lg rounded-lg text-white flex-1 text-center py-5"
                        onClick={() => setCookieAcceptance(false)}>
                        Nee
                    </div>
                    <div className="bg-persona-accept font-semibold cursor-pointer bg-opacity-80 hover:bg-opacity-100 hover:shadow-lg text-lg rounded-lg text-white flex-1 text-center py-5"
                        onClick={() => setCookieAcceptance(true)}>
                        Ja
                    </div>
                </div>
                <div className="text-center text-sm text-persona-white py-5 ">
                    <p>De cookies helpen ons het gebruikersgedrag te analyseren.</p>
                    <p>Er worden geen persoonlijke gegevens gebruikt, opgeslagen of verstuurd.</p>
                </div>
            </div>
        </div>
    )
}

export default CookieModal