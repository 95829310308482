
var fs = require('fs');
fs.readFile('src/metadata.json', function (err, content) {
    if (err) throw err;
    var metadata = JSON.parse(content);
    metadata.buildMinor = metadata.buildMinor + 1;

    const date = new Date();
    metadata.lastBuiltAt = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')} - ${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;

    fs.writeFile('src/metadata.json', JSON.stringify(metadata, null, 4), function (err) {
        if (err) throw err;
        console.log(`Current version of [ ${metadata.title} ] is [ ${metadata.buildMajor}.${metadata.buildMinor} ]`);
    })
});