import create from 'zustand'
import produce from "immer";
import metadata from "../metadata.json";
import useUserStore from "./userStore";
import {useAuth} from "../Auth/auth";

const host = process.env.REACT_APP_SERVER_HOST;
const port = process.env.REACT_APP_API_PORT;

export const useFeedbackStore = create((set, get) => ({
    
    // notes
    editableNote: null,
    setEditableNote: (originalFragment) => set(async state => {
        
        if (originalFragment == null) {
            set({ editableNote: null })
            return;
        }
        
        let previous = await state.hasPreviousNote(originalFragment?.Properties.Id)
        
        if (previous != null) {
            if (previous.length > 0)
                previous = previous[0]
            previous.original_fragment = JSON.parse(previous.original_fragment);
            
            set({ editableNote: previous })

            return;
        }
        
        const note = {
            index: 0,
            node_index: originalFragment.Properties.Id,
            edited_text: originalFragment.Properties.Text,
            comments: "",
            original_fragment: originalFragment
        };

        set({ editableNote: note })
    }),
    
    submitNote: (note) => set(state => {
        
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${useUserStore.getState().tokenData.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: useUserStore.getState().tokenData.id,
                project_title: metadata.title,
                node_index: note.node_index,
                edited_text: note.edited_text,
                comments: note.comments,
                original_fragment: note.original_fragment,
                updated_at: new Date().toISOString()
            })
        };
        const fullUrl = `${host}:${port}/api/feedback/add`;

        return fetch(fullUrl, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (!data.error)
                    set({ editableNote: null })
                    
                return data.error ? null : data;
            })
            .catch(error => {
                console.log("ERROR", error)
                return null;
            })
    }),
    
    deleteNote: (editedNote) => set(state => {
        //console.log('trying to delete', editedNote)
    }),
    
    hasPreviousNote: (id) => {
        
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${useUserStore.getState().tokenData.token}`,
                'Content-Type': 'application/json'
            }
        };

        const searchParams = new URLSearchParams({
            project_title: metadata.title,
            nodeId: id,
            email: useUserStore.getState().tokenData.email,
            match_phase: true
        });
        const fullUrl = `${host}:${port}/api/feedback` + (searchParams && '?' + searchParams)

        return fetch(fullUrl, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                return data.error ? null : data;
            })
            .catch(error => {
                return null;
            })
    },
}))

export default useFeedbackStore;