import React from 'react';
import { useGeneralStore } from '../Stores/store';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const Popup = ({ details }) => {
    
    const removePopup = useGeneralStore(state => state.removePopup);
    
    return (
        <div className="bg-black bg-opacity-70 absolute w-screen h-screen top-0 left-0 flex select-none">
            <div className="bg-persona rounded-3xl shadow-lg w-5/6 h-64 max-w-lg m-auto py-5 px-8 flex flex-col relative
                border-2 space-y-2">
                <div className="text-2xl font-semibold text-persona-white flex-grow-0">{details.title !== '' ? details.title : "[no title]" }</div>
                <div className="text-base text-opacity-80 text-persona-white flex-grow">{details.content}</div>
                <div onClick={() => { removePopup(); details.callback(); }} className="bg-persona-greenLight shadow-md absolute right-2 bottom-2 h-16 w-16 cursor-pointer rounded-full text-white">
                    <AiOutlineCloseCircle className="h-full w-full p-2"/>
                </div>
            </div>
            
        </div>
    )
}

export default Popup